import * as React from "react"
import { graphql, Link } from "gatsby";
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import backToProjects from '../../../images/back-to-projects.svg'
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import parisCafe1 from "../../../images/paris-kafe/paris-kafe-1.jpg"
import parisCafe2 from "../../../images/paris-kafe/paris-kafe-2.jpg"
import parisCafe3 from "../../../images/paris-kafe/paris-kafe-3.jpg"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ParisCafe = () => {
    const { t } = useTranslation();
    const options = {
        buttons: {
            showFullscreenButton: false,
            showDownloadButton: false,
            showThumbnailsButton: false,
            showAutoplayButton: false,
        }
    }
    return (
        <Layout>
            <Seo title="Paris Cafe" />
            <div className="mt-40 relative flex items-center justify-center md:mt-28">
                <div className="max-w-full overflow-hidden wrapper">
                    <div className="inline-block overflow-hidden whitespace-nowrap animate-marquee">
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">PARIS CAFE</p>
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">PARIS CAFE</p>
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">PARIS CAFE</p>
                    </div>
                </div>
                <h1 className="mb-0 font-first text-default text-5xl font-semibold absolute m-auto uppercase md:text-3xl md:-mt-1 -mt-10">PARIS CAFE</h1>
            </div>
            <div className="w-full">
                <SimpleReactLightbox>
                    <SRLWrapper options={options}>
                        <div className="w-full flex items-center justify-between flex-wrap md:mt-10">
                            <img src={parisCafe1} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisCafe2} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisCafe3} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                        </div>
                    </SRLWrapper>
                </SimpleReactLightbox>
            </div>
            <Link to="../" className="font-first text-default text-xl bg-slate-600/10 pr-3 flex items-center w-fit mt-24 ml-16 hover:ml-10 transition-all duration-300 rounded-3xl md:mt-10 md:mx-auto">
                <img src={backToProjects} alt="Projelere Dön" className="mr-3" />
                {t("GeriDon")}
            </Link>
        </Layout>
    )
}

export default ParisCafe;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;